exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-accept-payments-account-to-account-payment-tsx": () => import("./../../../src/pages/accept-payments-account-to-account-payment.tsx" /* webpackChunkName: "component---src-pages-accept-payments-account-to-account-payment-tsx" */),
  "component---src-pages-accept-payments-ecommerce-and-online-payments-tsx": () => import("./../../../src/pages/accept-payments-ecommerce-and-online-payments.tsx" /* webpackChunkName: "component---src-pages-accept-payments-ecommerce-and-online-payments-tsx" */),
  "component---src-pages-accept-payments-in-store-payments-tsx": () => import("./../../../src/pages/accept-payments-in-store-payments.tsx" /* webpackChunkName: "component---src-pages-accept-payments-in-store-payments-tsx" */),
  "component---src-pages-accept-payments-invoice-payments-tsx": () => import("./../../../src/pages/accept-payments-invoice-payments.tsx" /* webpackChunkName: "component---src-pages-accept-payments-invoice-payments-tsx" */),
  "component---src-pages-accept-payments-payment-links-tsx": () => import("./../../../src/pages/accept-payments-payment-links.tsx" /* webpackChunkName: "component---src-pages-accept-payments-payment-links-tsx" */),
  "component---src-pages-accept-payments-qr-code-payments-tsx": () => import("./../../../src/pages/accept-payments-qr-code-payments.tsx" /* webpackChunkName: "component---src-pages-accept-payments-qr-code-payments-tsx" */),
  "component---src-pages-affiliate-partners-tsx": () => import("./../../../src/pages/affiliate-partners.tsx" /* webpackChunkName: "component---src-pages-affiliate-partners-tsx" */),
  "component---src-pages-affiliate-signups-tsx": () => import("./../../../src/pages/affiliate-signups.tsx" /* webpackChunkName: "component---src-pages-affiliate-signups-tsx" */),
  "component---src-pages-b-2-b-ecommerce-store-app-tsx": () => import("./../../../src/pages/b2b-ecommerce-store-app.tsx" /* webpackChunkName: "component---src-pages-b-2-b-ecommerce-store-app-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bulk-payments-tsx": () => import("./../../../src/pages/bulk-payments.tsx" /* webpackChunkName: "component---src-pages-bulk-payments-tsx" */),
  "component---src-pages-business-toolkit-terms-and-conditions-tsx": () => import("./../../../src/pages/business-toolkit-terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-business-toolkit-terms-and-conditions-tsx" */),
  "component---src-pages-commercial-invoices-for-export-tsx": () => import("./../../../src/pages/commercial-invoices-for-export.tsx" /* webpackChunkName: "component---src-pages-commercial-invoices-for-export-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-demos-tsx": () => import("./../../../src/pages/demos.tsx" /* webpackChunkName: "component---src-pages-demos-tsx" */),
  "component---src-pages-dropshipping-tsx": () => import("./../../../src/pages/dropshipping.tsx" /* webpackChunkName: "component---src-pages-dropshipping-tsx" */),
  "component---src-pages-e-book-tsx": () => import("./../../../src/pages/e-book.tsx" /* webpackChunkName: "component---src-pages-e-book-tsx" */),
  "component---src-pages-ecommerce-store-tsx": () => import("./../../../src/pages/ecommerce-store.tsx" /* webpackChunkName: "component---src-pages-ecommerce-store-tsx" */),
  "component---src-pages-help-center-tsx": () => import("./../../../src/pages/help-center.tsx" /* webpackChunkName: "component---src-pages-help-center-tsx" */),
  "component---src-pages-how-fena-terminal-works-tsx": () => import("./../../../src/pages/how-fena-terminal-works.tsx" /* webpackChunkName: "component---src-pages-how-fena-terminal-works-tsx" */),
  "component---src-pages-how-to-pay-with-fena-tsx": () => import("./../../../src/pages/how-to-pay-with-fena.tsx" /* webpackChunkName: "component---src-pages-how-to-pay-with-fena-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inventory-and-stock-management-tsx": () => import("./../../../src/pages/inventory-and-stock-management.tsx" /* webpackChunkName: "component---src-pages-inventory-and-stock-management-tsx" */),
  "component---src-pages-learn-more-about-quickbooks-and-fena-tsx": () => import("./../../../src/pages/learn-more-about-quickbooks-and-fena.tsx" /* webpackChunkName: "component---src-pages-learn-more-about-quickbooks-and-fena-tsx" */),
  "component---src-pages-marketing-accountants-tsx": () => import("./../../../src/pages/marketing/accountants.tsx" /* webpackChunkName: "component---src-pages-marketing-accountants-tsx" */),
  "component---src-pages-marketing-automotive-tsx": () => import("./../../../src/pages/marketing/automotive.tsx" /* webpackChunkName: "component---src-pages-marketing-automotive-tsx" */),
  "component---src-pages-marketing-business-tools-tsx": () => import("./../../../src/pages/marketing/business-tools.tsx" /* webpackChunkName: "component---src-pages-marketing-business-tools-tsx" */),
  "component---src-pages-marketing-cbd-payments-tsx": () => import("./../../../src/pages/marketing/cbd-payments.tsx" /* webpackChunkName: "component---src-pages-marketing-cbd-payments-tsx" */),
  "component---src-pages-marketing-cbd-retail-and-wholesale-tsx": () => import("./../../../src/pages/marketing/cbd-retail-and-wholesale.tsx" /* webpackChunkName: "component---src-pages-marketing-cbd-retail-and-wholesale-tsx" */),
  "component---src-pages-marketing-property-manager-long-term-tsx": () => import("./../../../src/pages/marketing/property-manager-long-term.tsx" /* webpackChunkName: "component---src-pages-marketing-property-manager-long-term-tsx" */),
  "component---src-pages-marketing-property-manager-short-term-tsx": () => import("./../../../src/pages/marketing/property-manager-short-term.tsx" /* webpackChunkName: "component---src-pages-marketing-property-manager-short-term-tsx" */),
  "component---src-pages-marketing-wholesalers-logistic-tsx": () => import("./../../../src/pages/marketing/wholesalers-logistic.tsx" /* webpackChunkName: "component---src-pages-marketing-wholesalers-logistic-tsx" */),
  "component---src-pages-open-banking-data-apis-tsx": () => import("./../../../src/pages/open-banking-data-apis.tsx" /* webpackChunkName: "component---src-pages-open-banking-data-apis-tsx" */),
  "component---src-pages-order-aggregation-and-management-tsx": () => import("./../../../src/pages/order-aggregation-and-management.tsx" /* webpackChunkName: "component---src-pages-order-aggregation-and-management-tsx" */),
  "component---src-pages-order-and-invoice-generation-tsx": () => import("./../../../src/pages/order-and-invoice-generation.tsx" /* webpackChunkName: "component---src-pages-order-and-invoice-generation-tsx" */),
  "component---src-pages-order-fena-nfc-card-tsx": () => import("./../../../src/pages/order-fena-nfc-card.tsx" /* webpackChunkName: "component---src-pages-order-fena-nfc-card-tsx" */),
  "component---src-pages-order-form-tsx": () => import("./../../../src/pages/order-form.tsx" /* webpackChunkName: "component---src-pages-order-form-tsx" */),
  "component---src-pages-order-management-tsx": () => import("./../../../src/pages/order-management.tsx" /* webpackChunkName: "component---src-pages-order-management-tsx" */),
  "component---src-pages-ordering-and-terminal-apps-tsx": () => import("./../../../src/pages/ordering-and-terminal-apps.tsx" /* webpackChunkName: "component---src-pages-ordering-and-terminal-apps-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-payment-overview-tsx": () => import("./../../../src/pages/payment-overview.tsx" /* webpackChunkName: "component---src-pages-payment-overview-tsx" */),
  "component---src-pages-plug-ins-tsx": () => import("./../../../src/pages/plug-ins.tsx" /* webpackChunkName: "component---src-pages-plug-ins-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-information-management-tsx": () => import("./../../../src/pages/product-information-management.tsx" /* webpackChunkName: "component---src-pages-product-information-management-tsx" */),
  "component---src-pages-product-inventory-tsx": () => import("./../../../src/pages/product-inventory.tsx" /* webpackChunkName: "component---src-pages-product-inventory-tsx" */),
  "component---src-pages-recurring-payments-tsx": () => import("./../../../src/pages/recurring-payments.tsx" /* webpackChunkName: "component---src-pages-recurring-payments-tsx" */),
  "component---src-pages-sales-activities-tsx": () => import("./../../../src/pages/sales-activities.tsx" /* webpackChunkName: "component---src-pages-sales-activities-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-service-management-tsx": () => import("./../../../src/pages/service-management.tsx" /* webpackChunkName: "component---src-pages-service-management-tsx" */),
  "component---src-pages-shipping-management-tsx": () => import("./../../../src/pages/shipping-management.tsx" /* webpackChunkName: "component---src-pages-shipping-management-tsx" */),
  "component---src-pages-supplier-management-tsx": () => import("./../../../src/pages/supplier-management.tsx" /* webpackChunkName: "component---src-pages-supplier-management-tsx" */),
  "component---src-pages-terms-and-conditions-business-app-tsx": () => import("./../../../src/pages/terms-and-conditions-business-app.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-business-app-tsx" */),
  "component---src-pages-terms-and-conditions-fena-consumer-app-tsx": () => import("./../../../src/pages/terms-and-conditions-fena-consumer-app.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-fena-consumer-app-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-variable-recurring-payments-tsx": () => import("./../../../src/pages/variable-recurring-payments.tsx" /* webpackChunkName: "component---src-pages-variable-recurring-payments-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

